import { render, staticRenderFns } from "./person.html?vue&type=template&id=3d3367b5&scoped=true&"
import script from "./person.vue?vue&type=script&lang=ts&"
export * from "./person.vue?vue&type=script&lang=ts&"
import style0 from "./person.scss?vue&type=style&index=0&id=3d3367b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d3367b5",
  null
  
)

export default component.exports